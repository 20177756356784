import styles from "./advicepage.module.css";
import Advice from "../../components/advice/advice";
import { Helmet } from "react-helmet-async";

function AdvicePage() {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Гид по чистоте: Сделайте уборку проще</title>
        <meta
          name="description"
          content="Уборка домов и квартир в Порто. Гид по чистоте: Сделайте уборку проще "
        />
        <link rel="canonical" href="https://portocleaning.com/advice" />
      </Helmet>
      <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>
        Гид по чистоте: Сделайте уборку проще
      </h2>
      <Advice></Advice>
    </div>
  );
}

export default AdvicePage;
