import styles from "./hone.module.css";

const Hone = () => {
  return (
    <div className={styles.main}>
      <h1>Мы заботимся о чистоте, чтобы Вы могли заботиться о Важном.</h1>
    </div>
  );
};

export default Hone;
