import { useEffect, useRef, useState } from "react";
import styles from "./orderpagepersonal.module.css";
import { useParams } from "react-router-dom";
import Order from "../../components/order/order";
import OrderTime from "../../components/ordertime/ordertime";
import OrderDop from "../../components/orderdop/orderdop";
import { Helmet } from "react-helmet-async";

const textAnimationt = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: { delay: 0, duration: 0.2 },
  }),
};

function OrderPagePersonal() {
  const param = useParams().clean;

  const autofocus = useRef();
  const [ok, setok] = useState(false);
  const [time, settime] = useState(false);
  const [dop, setDop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (param === "timeclean") {
      settime(true);
    } else {
      if (param?.includes("clean")) {
        setDop(true);
      } else {
        setok(true);
      }
    }
  }, []);

  return (
    <div className={styles.main}>
      {ok && <Order name={param ? param : ""}></Order>}
      {time && <OrderTime name={param ? param : ""}></OrderTime>}
      {dop && <OrderDop name={param ? param : ""}></OrderDop>}
    </div>
  );
}

export default OrderPagePersonal;
