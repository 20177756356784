import styles from "./company.module.css";
import Contact from "../../components/contact/contact";
import Wecan from "../../components/wecan/wecan";
import { Helmet } from "react-helmet-async";

function Company() {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Русскоязычная клининговая компания в Порту.</title>
        <meta
          name="description"
          content="Уборка домов и квартир в Порту. Клинеры говорят по русски"
        />
        <link rel="canonical" href="https://www.portocleaning.com/company" />
      </Helmet>
      <Wecan></Wecan>
      <Contact work={false}></Contact>
    </div>
  );
}

export default Company;
