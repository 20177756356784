import styles from "./afterrepairclean.module.css";
import { listLocR } from "../../utils/data";
import CleanType from "../../components/cleantype/cleantype";
import { Helmet } from "react-helmet-async";

function Afterrepairclean() {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Уборка после ремонта в Порту</title>
        <meta
          name="description"
          content="Уборка после ремонта домов и квартир в Порту"
        />
        <link
          rel="canonical"
          href="https://www.portocleaning.com/afterrepairclean"
        />
      </Helmet>
      <CleanType
        nameone="Услуги"
        nametwo="Уборка после ремонта"
        namethree="Что мы делаем:"
        list={listLocR}
      ></CleanType>
    </div>
  );
}

export default Afterrepairclean;
