import styles from "./regularclean.module.css";
import { listLoc } from "../../utils/data";
import Status from "../../components/status/status";
import Compoundnew from "../../components/compoundnew/compoundnew";
import { useEffect } from "react";
import CleanType from "../../components/cleantype/cleantype";
import { Helmet } from "react-helmet-async";

function Regularclean() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        <title>Регулярная уборка домов и квартир в Порту</title>
        <meta
          name="description"
          content="Регулярная уборка домов и квартир в Порту"
        />
        <link rel="canonical" href="https://www.portocleaning.com/deepclean" />
      </Helmet>
      <CleanType
        nameone="Услуги"
        nametwo="Базовая уборка"
        namethree="Что мы делаем:"
        list={listLoc}
      ></CleanType>
    </div>
  );
}

export default Regularclean;
