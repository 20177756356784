import styles from "./orderpage.module.css";
import None from "../../components/none/none";
import { list, listdop } from "../../utils/data";
import Cleaningnew from "../../components/cleaningnew/cleaningnew";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

const textAnimationt = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: { delay: 3, duration: 3 },
  }),
};

function OrderPage() {
  return (
    <motion.div className={styles.main} initial="hidden" whileInView="visible">
      <Helmet>
        <title>Заказать уборку домов и квартир в Порту</title>
        <meta
          name="description"
          content="Заказ уборки домов и квартир в Порту. Заказать клининговые услуги в Порту, мойка окон, стирка и глажка"
        />
        <link rel="canonical" href="https://www.portocleaning.com/order" />
      </Helmet>
      <Cleaningnew
        name="Какую уборку хотите заказать?"
        list={list}
      ></Cleaningnew>

      <None hh={10}></None>

      <Cleaningnew
        name="Только дополнительные услуги?"
        list={listdop}
      ></Cleaningnew>

      <None hh={100}></None>
    </motion.div>
  );
}

export default OrderPage;
