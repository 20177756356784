import styles from "./clean.module.css";
import { list, listdop } from "../../utils/data";
import Cleaningnew from "../../components/cleaningnew/cleaningnew";
import None from "../../components/none/none";
import { Helmet } from "react-helmet-async";

function Clean() {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>
          Уборка домов и квартир в Порту.Русскоязычный клининг в Порту
        </title>
        <meta
          name="description"
          content="Регулярная уборка домов и квартир в Порту, клининг, генеральная уборка"
        />
        <link rel="canonical" href="https://www.portocleaning.com/baseclean" />
      </Helmet>
      <div className={styles.section_1}>
        <Cleaningnew name="Уборка домов и квартир" list={list}></Cleaningnew>
        <None hh={20}></None>
        <Cleaningnew name="Дополнительные услуги" list={listdop}></Cleaningnew>
        <None hh={20}></None>
      </div>
    </div>
  );
}

export default Clean;
