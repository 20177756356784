import { useState } from "react";
import styles from "./coast.module.css";
import {
  typeClean,
  TtypeClean,
  commentalltwo,
  commentBw,
  commentBwn,
  pricebase,
  pricebaseroom,
  pricebasewc,
  timebaseroom,
  timebasewc,
  timebase,
} from "../../utils/data";
import Cardbase from "../../components/cardbase/cardbase";
import Maismenos from "../../components/maismenos/maismenos";
import { Helmet } from "react-helmet-async";

const Coast = () => {
  const [room, setRoom] = useState(1);
  const [roomn, setRoomn] = useState("комната");
  const [wc, setWc] = useState(1);
  const [wcn, setWcn] = useState("туалет");
  const [actionType, setActionType] = useState({
    name: "Базовая уборка (раз в неделю)",
    kof: 1,
    com: commentalltwo,
    koftime: 1,
    comw: commentBw,
    comwn: commentBwn,
  });

  const onClickType = (it: TtypeClean) => {
    setActionType(it);
  };

  return (
    <div className={styles.main}>
      <Helmet>
        <title>Стоимость уборки в Порту. Цены</title>
        <meta
          name="description"
          content="Уборка домов и квартир в Порту, клининг, стоимость. Сколько стоит уборка дома?"
        />
        <link rel="canonical" href="https://www.portocleaning.com/price" />
      </Helmet>
      <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>
        Сколько стоит уборка дома?
      </h2>
      <h3
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >{`Если в вашей квартире ${room} ${roomn} и ${wc} ${wcn}`}</h3>
      <div className={styles.section_2}>
        <Maismenos
          nameone="комната"
          nametwo="комнаты"
          namethree="комнат"
          func={setRoom}
          funcn={setRoomn}
        ></Maismenos>
        <Maismenos
          nameone="туалет"
          nametwo="туалета"
          namethree="туалетов"
          func={setWc}
          funcn={setWcn}
        ></Maismenos>
      </div>

      <div className={styles.price}>
        {typeClean.map((item, index) => {
          return (
            <div
              className={styles.typeclean}
              onClick={() => onClickType(item)}
              key={index}
            >
              <Cardbase
                name={item.name}
                kof={item.kof}
                key={index}
                actionType={actionType.name}
                base={pricebaseroom * room + pricebasewc * wc + pricebase}
              ></Cardbase>
            </div>
          );
        })}
      </div>
      <article className={styles.aticle}>
        {`Примерное время уборки ${(
          (timebaseroom * room + timebasewc * wc + timebase) *
          actionType.koftime
        ).toFixed(1)} ч.`}

        {actionType.com.map((item, index) => {
          return (
            <div className={styles.itematicle} key={index}>
              {item}
            </div>
          );
        })}
      </article>
      <article className={styles.aticle}>
        {`Что делаем:`}

        {actionType.comw.map((item, index) => {
          return (
            <div className={styles.itematicle} key={index}>
              {item}
            </div>
          );
        })}
      </article>
      <article className={styles.aticle}>
        {`Обратите внимание, данные работы не входят в список выполняемых:`}

        {actionType.comwn.map((item, index) => {
          return (
            <div className={styles.itematicle} key={index}>
              {item}
            </div>
          );
        })}
      </article>
    </div>
  );
};

export default Coast;
